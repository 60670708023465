import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RecaptchaComponent } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../service/notification.service';
import { ApiService } from '../../service/api.service';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpParams } from '@angular/common/http';
import { UtilityService } from '../../service/utility.service';

import { uniq as lodashUniq } from 'lodash';
import { CheckboxChangeEvent } from 'primeng/checkbox';

@Component({
  selector: 'app-fee-debit-account',
  templateUrl: './fee-debit-account.component.html',
  styleUrl: './fee-debit-account.component.scss'
})
export class FeeDebitAccountComponent implements OnInit {

  public isLoading: boolean = false;
  public is_loading: boolean = false;
  public accountNumber: any;
  public userRole: any;
  public modal: NgbModalRef | null | undefined;
  public companyEdit: any;
  public selectedCompany: any;
  public selectedAccountNumber: any;

  public company = [
    { label: 'All', value: null },
  ];

  public accountNumberList = [
    { label: 'All', value: null },
  ];

  public currentPage = 1;
  public itemsPerPage = 10;
  public setPage_size = 10;
  public totalItems = 0;
  public transactionDataList = [];

  public downloadLoading: boolean = false;
  public errorMessage: string | null | undefined;

  public tableDataForMaker: any[] | undefined | null = [];
  public tableDataForChecker: any[] | undefined | null = [];
  public reCaptchaModal: NgbModalRef | null | undefined;

  public recaptchaSiteKey = environment['recaptChaSiteKey'];
  public recaptchaToken = null;

  public accountNumberRequestIDList: any[] =[];
  public rejectAccountNumberIDList: any[] =[];
  public rejectReason: any;
  public rejectedReasonModal: any;
  public isCheckAll: any;
  public countIsCheckAll = 0;

  public companyList = [];

  @ViewChild('recaptchaRef') public recaptchaRef: RecaptchaComponent | undefined;

  constructor(
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private apiService: ApiService,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
    private utilityService: UtilityService
  ) {
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  initialSetting(): void {
    this.translateService
      .onLangChange
      .subscribe((event: LangChangeEvent) => {
      this.loadTexts();
        this.getUserCompany()
      });
    this.loadTexts();
    this.getUserCompany();
    this.getUserList();
    this.getRequestAccountNumberList();
  }

  resolve(token: any): void {
    this.recaptchaToken = token;
  }

  getUserCompany(httpParams?: HttpParams): void {
    this.apiService
      .getUserCompany(httpParams)
      .subscribe({
        next: (res: any) => {
          this.company = res.results.map(
            (obj: any) => {
              return {
                label: obj['company_name_en'],
                value: obj['id']
              }
            }
          );
          this.company.unshift({
            label: this.translateService.instant('FILTER.ALL'),
            value: null
            }
          );
          this.accountNumberList = res.results.map(
            (obj: any) => {
              return {
                label: obj['account_number'] ?? '-',
                value: obj['id']
              }
            }
          );
          this.accountNumberList.unshift({
            label: this.translateService.instant('FILTER.ALL'),
            value: null
            }
          )
        }
      });
  }


  getUserList(): void {
    if (this.canChecker()) {
      return;
    }
    this.is_loading = true;
    let params = new HttpParams();
    params = params.append('page', this.currentPage.toString());
    params = params.append('page_size', this.setPage_size.toString());
    if (this.selectedCompany) {
      params = params.append('id', this.selectedCompany);
    }
    this.apiService
      .getUserCompany(params)
      .subscribe({
        next: (res: any) => {
          this.tableDataForMaker = res.results
          this.companyList = res.results
          if (this.canMaker() || this.canViewer()) {
            this.totalItems = res.count;
          }
          this.is_loading = false;
        }
      });
  }

  getRequestAccountNumberList(): void {
    if (this.canMaker() || this.canViewer()) {
      return;
    }
    this.is_loading = true;
    let params = new HttpParams();
    params = params.append('request_status', 'pending');
    params = params.append('page', this.currentPage.toString());
    params = params.append('page_size', this.setPage_size.toString());
    if (this.selectedCompany) {
      params = params.append('company_id', this.selectedCompany);
    }
    this.apiService
      .getRequestAccountNumber(params)
      .subscribe({
        next: (res: any) => {
          this.tableDataForChecker = res.results
          if (this.canChecker()) {
            this.totalItems = res.count;
          }
          this.is_loading = false;
        }
      });
  }

  recaptChaResolved(recaptchaToken: any, modalName: any, data?: any): void {
    setTimeout(() => {
      this.closeRecaptchaModal();
    }, 500)
    if (modalName === 'approve') {
      this.approvalRequest(recaptchaToken)
    } else if (modalName === 'reject') {
      this.rejectRequest(recaptchaToken)
    }
  }

  closeRecaptchaModal(): void {
    this.reCaptchaModal?.close();
  }

  editAccountNumber(event: any, edit: any, data: any): void {
    this.resetVariable();
    this.modal = this.modalService.open(edit, {backdrop: 'static', keyboard: false});
    this.companyEdit = data.company_name_en
  }

  openApprove(event: any, approve: any) {
    this.modal = this.modalService.open(approve, {backdrop: 'static', keyboard: false});
  }

  openReject(event: any, reject: any) {
    this.modal = this.modalService.open(reject, {backdrop: 'static', keyboard: false});
  }

  createAccountNumber(): void {
    this.errorMessage = null;
    if (this.accountNumber.length != 10) {
      this.errorMessage = this.translateService.instant('FEE-DEBIT-ACCOUNT.ERROR-10-DIGITS');
      return;

    }
    const data = {
      company: this.companyEdit,
      account_number: this.accountNumber,
      recaptchaToken: this.recaptchaToken
    };
    this.is_loading = true;
    this.apiService
      .createAccountNumber(data)
      .subscribe({
        next: (res: any) => {
          this.notificationService.showSuccess(res);
          this.errorMessage = res;
          this.is_loading = false
          this.close();
          this.getUserList();
          this.getUserCompany();
        },
        error: (err) => {
          this.errorMessage =  this.utilityService.getErrorString(err.error)
          this.is_loading = false;
          this.notificationService.showNotification(err);
          this.getUserCompany();
        }
      });
  }

  approvalRequest(token: any) {
    this.is_loading = true;
    this.tableDataForChecker?.filter((obj: any) => {
      return obj.status ? this.accountNumberRequestIDList.push(obj.id) : null;
    });
    const approvedList = lodashUniq(this.accountNumberRequestIDList);
    const data = {
      account_number_request_id_list: lodashUniq(this.accountNumberRequestIDList),
      request_status: 'approved',
      recaptchaToken: token
    };
    this.apiService
      .approveRequestAccountNumber(data)
      .subscribe({
        next: (res) => {
          this.tableDataForChecker = this.tableDataForChecker?.filter((obj: any) => !approvedList.includes(obj.id))
          this.totalItems = this.totalItems - lodashUniq(this.accountNumberRequestIDList).length;
          this.is_loading = false;
          this.isCheckAll = false;
          this.countIsCheckAll = 0;
          this.accountNumberRequestIDList = [];
          this.notificationService.showSuccess();
          this.modal?.close();
        },
        error: (err) => {
          this.is_loading = false;
          this.accountNumberRequestIDList = [];
          this.notificationService.showNotification(err);
        }
      });
  }

  rejectRequest(token: any) {
    this.is_loading = true;
    this.tableDataForChecker?.filter((obj: any) => {
      console.log('www', obj.id)
      return obj.status ? this.rejectAccountNumberIDList.push(obj.id) : null;
    });
    console.log(this.rejectAccountNumberIDList);
    const rejectID = this.rejectAccountNumberIDList;
    const data = {
      account_number_request_id_list: this.rejectAccountNumberIDList,
      request_status: 'rejected',
      rejected_reason: this.rejectReason,
      recaptchaToken: token
    };
    this.clearRejectReason()
    this.apiService
      .rejectRequestAccountNumber(data)
      .subscribe({
        next: (res) => {
          this.tableDataForChecker = this.tableDataForChecker?.filter((obj: any) => obj.id !== rejectID);
          this.totalItems = this.totalItems - lodashUniq(this.rejectAccountNumberIDList).length;
          this.countIsCheckAll = 0;
          this.is_loading = false;
          this.rejectAccountNumberIDList = []
          this.notificationService.showSuccess();
          this.modal?.close();
          this.getRequestAccountNumberList();
        },
        error: (err) => {
          this.is_loading = false;
          this.notificationService.showNotification(err);
          this.modal?.close();
        }
      });
  }

  popRejectedReason(event: any, rejectedReason: any, data: any): void {
    this.rejectedReasonModal = [data];
    this.modal = this.modalService.open(rejectedReason, {backdrop: 'static', keyboard: false, size: 'lg'});
  }

  clearRejectReason(): void {
    this.rejectReason = null;
  }

  loadTexts(): void {
  }

  resetVariable(): void {
    this.errorMessage = null;
    this.accountNumber = null;
  }

  close(): void {
    this.modal?.close();
    this.modal = null;
    this.accountNumber = null;
    this.recaptchaToken = null;
  }

  pageChanged(): void {
    this.isCheckAll = false;
    this.countIsCheckAll = 0;
    this.tableDataForMaker = null;
    this.getUserList();
    this.getRequestAccountNumberList();
  }

  setPageSide(event: any): void {
    this.isCheckAll = false;
    this.countIsCheckAll = 0;
    this.setPage_size = event;
    this.currentPage = 1;
    this.getUserList();
    this.getRequestAccountNumberList();
  }

  searchButtonClicked(): void {
    this.currentPage = 1;
    this.getUserList();
    this.getRequestAccountNumberList();
  }

  clearData(): void {
    this.selectedCompany = null;
  }

  clearFilter(): void {
    this.clearData();
    this.clearCheckAll();
    this.searchButtonClicked();
  }

  clearCheckAll(): void {
    this.isCheckAll = false;
    this.countIsCheckAll = 0;
  }

  checkAll(event: CheckboxChangeEvent): void {
    if (!event.checked) {
      this.countIsCheckAll = 0;
    }
    if (event.checked) {
      this.countIsCheckAll = this.tableDataForChecker?.length ?? 0;
    }
    this.tableDataForChecker?.map(obj => {
      return obj['status'] = event.checked;
      }
    );
  }

  checkBox(event: CheckboxChangeEvent): void {
    this.isCheckAll = false;
    if (event.checked) {
      this.countIsCheckAll++;
    }
    if (!event.checked) {
      this.countIsCheckAll--;
    }
    if (this.tableDataForChecker?.length === this.countIsCheckAll) {
      this.isCheckAll = true;
    }
  }

  canMaker(): boolean {
    return this.authenticationService.isSuperMaker() || this.authenticationService.isMaker();
  }

  canChecker(): boolean {
    return this.authenticationService.isSuperChecker() || this.authenticationService.isChecker();
  }

  canViewer(): boolean {
    return this.authenticationService.isViewerOperation() || this.authenticationService.isViewerImpAndHelp();
  }

}

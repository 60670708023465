<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{'MENU.USER-MANAGEMENT' | translate}}</h3>
    </div>
  </div>
</div>

<p-toast position="top-right"></p-toast>
<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="loading" (submit)="searchButtonClicked()" (clear)="clearFilter()"></app-filter-header>

  <div class="row">
    <div class="col-md-6 p-5px">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          [options]="companyList"
          [(ngModel)]="filteredCompany"
          [autoDisplayFirst]="false"
          placeholder="{{'EMAIL-DASHBOARD.COMPANY' | translate}}"
          [style]="{'z-index': 3}"
          [showClear]="true"
        >
        </p-dropdown>
        <label for="permission-role">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</label>
      </div>
    </div>

    <div class="col-md-6 p-5px">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          selectId="userRole"
          [options]="userRole"
          [(ngModel)]="selectedUserRole"
          [autoDisplayFirst]="false"
          placeholder="{{'TEXT.USER-ROLE' | translate}}"
          [style]="{'z-index': 3}"
        >
        </p-dropdown>
        <label for="user-role">{{'TEXT.USER-ROLE' | translate}}</label>
      </div>
    </div>
  </div>

</div>

<div class="box-parent position-relative z-1">
  <app-custom-pagination
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    [totalItems]="totalItems"
    (pageChange)="pageChanged()"
    (optionChangeValue)="setPageSide($event)"
  >
    <div class="row align-items-center" *ngIf="canMaker()">
      <div class="col-auto">
        <button type="button" class="btn btn-dv btn-wide" (click)="addUser($event, create)">
          {{'TEXT.CREATE-ACCOUNT' | translate}}
        </button>
      </div>
    </div>
    <div class="row align-items-center" *ngIf="canChecker()">
      <div class="col-auto">
        <button type="button" class="btn btn-dv btn-danger btn-wide"
                [disabled]="countIsCheckAll == 0 || countIsCheckAll > 1" (click)="openReject($event, reject)">
          {{'TEXT.REJECT-ACCOUNT' | translate}}
        </button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-dv btn-wide" [disabled]="!countIsCheckAll && !isCheckAll"
                (click)="openApprove($event, approve)">
          {{'TEXT.APPROVE-ACCOUNT' | translate}}
        </button>
      </div>
    </div>
  </app-custom-pagination>

  <div *ngIf="canMaker()" class="m-t-20">
    <p-table #tt class="text-center" tableStyleClass="table" [loading]="loading" *ngIf="tableDataForMaker && !isLoading"
             [columns]="tableCol" [value]="tableDataForMaker" [customSort]="true" [autoLayout]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col" style="width: 5%;">{{'COMMON.TABLE.ORDER' | translate}}</th>
          <th scope="col" scope="col">{{'USER-MANAGEMENT.UPDATED_DATE' | translate}}</th>
          <th scope="col">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</th>
          <th scope="col">{{'EMAIL-DASHBOARD.EMAIL' | translate}}</th>
          <th scope="col">{{'TEXT.ROLE' | translate}}</th>
          <th scope="col">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</th>
          <th scope="col">{{'USER-MANAGEMENT.STATUS' | translate}}</th>
          <th scope="col">{{'TEXT.REASON' | translate}}</th>
          <th scope="col" style="width: 12%;">{{'TEXT.SETTING-USER' | translate}}</th>
          <th scope="col">{{'TEXT.EDIT-ROLE' | translate}}</th>
          <th scope="col"  style="width: 10%;">{{'TEXT.RESET-PASSWORD' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>{{((currentPage - 1) * itemsPerPage) + index + 1}}</td>
          <td>{{data.updated_at}}</td>
          <td>{{data.username}}</td>
          <td>{{data.email || '-'}}</td>
          <td>{{data.group.name }}</td>
          <td>{{data.company}}</td>
          <td *ngIf="(data.status == 'Approved' || data.status == 'approved') && !canViewer()">
            {{'USER-MANAGEMENT.APPROVED' | translate}}
          </td>
          <td *ngIf="(data.status == 'Rejected' || data.status == 'rejected') && !canViewer()">
            {{'USER-MANAGEMENT.REJECTED' | translate}}
          </td>
          <td *ngIf="(data.status == 'Pending' || data.status == 'pending') && !canViewer()">
            {{'USER-MANAGEMENT.PENDING' | translate}}
          </td>
          <td >
            <fa-icon
              *ngIf="data.status == 'Rejected' || data.status == 'rejected'"
              [icon]="['fas', 'eye']"
              class="edit-btn"
              (click)="popRejectedReason($event, rejectedReason, data)"
            ></fa-icon>
          </td>
          <td style="text-align: center;text-align: -webkit-center; text-align: -moz-center" >
            <div *ngIf="!canEdit(data) || requestPending(data)" class="div-disable">
              <p-inputSwitch
                [disabled]="true"
                [ngModel]="data.is_active"
                class="p-input-switch-custom"
              ></p-inputSwitch>
            </div>
            <div class="p-switch" (click)="toggleSwitch(data, disable)" *ngIf="canEdit(data) && !requestPending(data)">
              <p-inputSwitch
                [disabled]="true"
                [ngModel]="data.is_active"
                 class="p-input-switch-custom"
              ></p-inputSwitch>
            </div>
          </td>
          <td >
            <fa-icon 
              *ngIf="!canEdit(data) || requestPending(data)"
              [icon]="['fas', 'pen-to-square']"
              class="edit-btn div-disable"
            ></fa-icon>
            <fa-icon 
              *ngIf="canEdit(data) && !requestPending(data)"
              [icon]="['fas', 'pen-to-square']"
              class="edit-btn"
              (click)="editUser($event, edit, data)"
            ></fa-icon>
          </td>
          <td >
            <fa-icon 
              *ngIf="!canEdit(data) || requestPending(data)"
              [icon]="['fas', 'lock']"
              class="edit-btn div-disable"
            ></fa-icon>
            <fa-icon 
              *ngIf="canEdit(data) && !requestPending(data)"
              [icon]="['fas', 'lock']"
              class="edit-btn"
              (click)="openResetPassword(data, resetPassword)"
            ></fa-icon>
          </td>

          <ng-template #rejectedReason let-modal>
            <div class="modal-header">
              <h3 class="m-0">{{'USER-MANAGEMENT.REJECT-REASON' | translate}}: </h3>
              <button type="button" class="btn-close" aria-label="Close" (click)="revertBack()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body bg-model">
              <div class="row bg-model">
                <div class="col-12 text-center bg-model">
                  <p-table [value]="rejectedReasonModal" [autoLayout]="true" dataKey="value" width="100%">
                    <ng-template pTemplate="header">
        <tr>
          <th scope="col" style="width: 25%;">{{'USER-MANAGEMENT.UPDATED_DATE' | translate}}</th>
          <th scope="col" style="width: 25%;">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</th>
          <th scope="col" style="width: 50%;">{{'USER-MANAGEMENT.REJECT-REASON' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data>
        <tr>
          <td>
            {{data.updated_at}}
          </td>
          <td>
            {{data.username}}
          </td>
          <td>
            {{data.rejected_reason}}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
</div>
<div class="modal-footer">
  <div class="text-center">
    <button type="button" class="btn btn-dv btn-clean btn-wide"
            (click)="revertBack()">{{'COMMON.CLOSE' | translate}}
    </button>
  </div>
</div>
          </ng-template>

          <ng-template #resetPassword let-modal>
            <div class="modal-header">
              <h5>{{'TEXT.RESET-PASSWORD' | translate}}</h5>
            </div>
            <div class="modal-body">
              <small class="text-red">** {{ 'USER-MANAGEMENT.CONFIRM-BY-RECAPTCHA' | translate }}</small>
              <re-captcha (resolved)="recaptChaResolved($event , 'resetPassword' , data)"
                          [siteKey]="recaptchaSiteKey"></re-captcha>
            </div>
            <div class="modal-footer-alternative">
              <div class="row no-gutters">
                <div class="col-12 text-right">
                  <button type="button" class="btn btn-dv btn-clean btn-wide" [disabled]="is_loading"
                          (click)="revertBack()">
                    <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
                    {{'COMMON.CANCEL' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template #disable let-modal>
            <div class="modal-header">
              <h5>  {{ 'USER-MANAGEMENT.CONFIRM-DISABLE-USER' | translate }}</h5>
            </div>
            <div class="modal-body">
              <h5>{{ 'USER-MANAGEMENT.USER' | translate }} : {{data.username}}</h5>
              <small class="text-red">** {{ 'USER-MANAGEMENT.CONFIRM-BY-RECAPTCHA' | translate }}</small>
              <re-captcha (resolved)="recaptChaResolved($event , 'disable' , data)"
                          [siteKey]="recaptchaSiteKey"></re-captcha>
            </div>
            <div class="modal-footer-alternative text-right">
              <button type="button" class="btn btn-dv btn-clean btn-wide" [disabled]="is_loading"
                      (click)="closeModalDisable()">
                <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
                {{'COMMON.CANCEL' | translate}}
              </button>
            </div>
          </ng-template>

          <ng-template #edit let-modal>
            <div class="modal-header">
              <h5 class="m-0">{{'TEXT.EDIT-ACCOUNT' | translate}}</h5>
              <button type="button" class="btn-close" aria-label="Close" (click)="revertBack()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row mt-3">
                <div class="col-md-6 p-5px">
                  <div class="dropdown-label-group">
                    <p-dropdown
                      class="dv-dropdown"
                      selectId="company"
                      [options]="roleList"
                      [(ngModel)]="selectedRoleList"
                      [autoDisplayFirst]="false"
                      placeholder="{{'TEXT.PERMISSION-ROLE' | translate}}"
                      [style]="{'z-index': 3}"
                    >
                    </p-dropdown>
                    <label for="permission-role">{{'TEXT.PERMISSION-ROLE' | translate}}</label>
                  </div>
                </div>
                <div class="col-md-6 p-5px">
                  <div class="dropdown-label-group">
                    <p-dropdown
                      class="dv-dropdown"
                      [options]="companyList"
                      [(ngModel)]="selectedCompany"
                      [autoDisplayFirst]="false"
                      placeholder="{{'EMAIL-DASHBOARD.COMPANY' | translate}}"
                      [ngStyle]="{'z-index': '3', 'opacity': !canSelectCompany ? '0.5' : '1'}"
                      [showClear]="true"
                      [disabled]="!canSelectCompany"
                    >
                    </p-dropdown>
                    <label for="permission-role">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</label>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedCompany || selectedRoleList">
                <small class="text-red">** {{ 'USER-MANAGEMENT.CONFIRM-BY-RECAPTCHA' | translate }}</small>
                <re-captcha (resolved)="recaptChaResolved($event , 'edit', data)"
                            [siteKey]="recaptchaSiteKey"></re-captcha>
              </div>
            </div>
            <div class="modal-footer-alternative">
              <div class="row no-gutters">
                <div class="col-12 text-right">
                  <button type="button" class="btn btn-dv btn-clean btn-wide" (click)="revertBack()">
                    <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
                    {{'COMMON.CANCEL' | translate}}
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
        </tr>
      </ng-template>
    </p-table>
    <div class="row m-t-20" *ngIf="!tableDataForMaker && !isLoading">
      <div class="col-12 text-center">
        <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
      </div>
    </div>
  </div>

  <div *ngIf="canViewer()" class="m-t-20">
    <p-table #tt class="text-center" tableStyleClass="table" [loading]="loading" *ngIf="tableDataForMaker && !isLoading"
             [columns]="tableCol" [value]="tableDataForMaker" [customSort]="true" [autoLayout]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col" style="width: 5%;">{{'COMMON.TABLE.ORDER' | translate}}</th>
          <th scope="col">{{'USER-MANAGEMENT.UPDATED_DATE' | translate}}</th>
          <th scope="col">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</th>
          <th scope="col">{{'EMAIL-DASHBOARD.EMAIL' | translate}}</th>
          <th scope="col">{{'TEXT.ROLE' | translate}}</th>
          <th scope="col">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</th>
          <th scope="col">{{'TEXT.ACCESSIBILITY' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>{{((currentPage - 1) * itemsPerPage) + index + 1}}</td>
          <td>{{data.updated_at}}</td>
          <td>{{data.username}}</td>
          <td>{{data.email || '-'}}</td>
          <td>{{data.group.name }}</td>
          <td>{{data.company}}</td>
          <td>
            <fa-icon *ngIf="data.is_active === false" [icon]="['fas', 'xmark']" aria-hidden="true"></fa-icon>
            <fa-icon *ngIf="data.is_active === true" [icon]="['fas', 'check']" aria-hidden="true"></fa-icon>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="row m-t-20" *ngIf="!tableDataForMaker && !isLoading">
      <div class="col-12 text-center">
        <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
      </div>
    </div>
  </div>

  <div *ngIf="canChecker()" class="m-t-20">
    <p-table
      #tt
      class="text-center"
      tableStyleClass="table"
      [loading]="loading"
      *ngIf="tableDataForChecker && !isLoading"
      [columns]="tableCol"
      [value]="tableDataForChecker"
      [autoLayout]="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col" style="width: 5%;" *ngIf="tableDataForChecker.length">
            <p-checkbox
              class="dv-checkbox"
              binary="true"
              [(ngModel)]="isCheckAll"
              (onChange)="checkAll($event)"
            ></p-checkbox>
          </th>
          <th scope="col" style="width: 5%;">{{'COMMON.TABLE.ORDER' | translate}}</th>
          <th scope="col">{{'USER-MANAGEMENT.UPDATED_DATE' | translate}}</th>
          <th scope="col">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</th>
          <th scope="col">{{'EMAIL-DASHBOARD.EMAIL' | translate}}</th>
          <th scope="col">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</th>
          <th scope="col">{{'TEXT.RESET-PASSWORD' | translate}}</th>
          <th scope="col">{{'TEXT.ORIGINAL-ROLE' | translate}}</th>
          <th scope="col">{{'TEXT.NEW-ROLE' | translate}}</th>
          <th scope="col">{{'TEXT.ACCESSIBILITY' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>
            <p-checkbox
              class="dv-checkbox"
              [(ngModel)]="data.status"
              binary="true"
              (onChange)="checkBox($event)"
            ></p-checkbox>
          </td>
          <td>{{((currentPage - 1) * itemsPerPage) + index + 1}}</td>
          <td>{{data.updated_date}}</td>
          <td>{{data.username || data.old_username}}</td>
          <td>{{data.email || '-'}}</td>
          <td>{{data.company_name || '-'}}</td>
          <td>
            <img
              src="../../../../assets/images/lock-reset.svg"
              *ngIf="data.is_reset_password === true; else notResetPassword"
              style="height: 25px;"
              alt="Not Reset Password"
            >
            <ng-template #notResetPassword>
              <i>-</i>
            </ng-template>
          </td>
          <td>{{data.old_group || '-'}}</td>
          <td>{{data.new_group || '-'}}</td>
          <td>
            <fa-icon *ngIf="data.is_active === false" [icon]="['fas', 'xmark']" aria-hidden="true"></fa-icon>
            <fa-icon *ngIf="data.is_active === true" [icon]="['fas', 'check']" aria-hidden="true"></fa-icon>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="row m-t-20" *ngIf="!tableDataForChecker && !isLoading">
      <div class="col-12 text-center">
        <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
      </div>
    </div>
  </div>

  <div class="row m-t-20" *ngIf="isLoading">
    <div class="col-12 text-center">
      <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']" size="2xl"></fa-icon>
    </div>
  </div>
</div>

<ng-template #create let-modal>
  <div class="modal-header">
    <h5 class="m-0">{{'TEXT.CREATE-ACCOUNT' | translate}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="revertBack()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mt-3">
      <div class="col-6 custom-label">
        <input type="text" class="form-control" id="username" [(ngModel)]="userName"
               placeholder="{{'PROFILE.LIST.FILTER.USERNAME' | translate}}" ng-required="userName">
        <label for="username">{{'PROFILE.LIST.FILTER.USERNAME' | translate}}</label>
      </div>
      <div class="col-6 custom-label">
        <input type="text" class="form-control" id="email" [(ngModel)]="changeEmail"
               placeholder="{{'EMAIL-DASHBOARD.EMAIL' | translate}}" ng-required="changeEmail">
        <label for="email">{{'EMAIL-DASHBOARD.EMAIL' | translate}}</label>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-6 p-5px">
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            [options]="companyList"
            [(ngModel)]="selectedCompany"
            [autoDisplayFirst]="false"
            placeholder="{{'EMAIL-DASHBOARD.COMPANY' | translate}}"
            [style]="{'z-index': 3}"
            [showClear]="true"
          >
          </p-dropdown>
          <label for="permission-role">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</label>
        </div>
      </div>
      <div class="col-md-6 p-5px">
        <div class="dropdown-label-group">
          <p-dropdown
            class="dv-dropdown"
            selectId="userRole"
            [options]="roleList"
            [(ngModel)]="selectedRoleList"
            [autoDisplayFirst]="false"
            placeholder="{{'TEXT.PERMISSION-ROLE' | translate}}"
            [style]="{'z-index': 3}"
            [showClear]="true"
          >
          </p-dropdown>
          <label for="permission-role">{{'TEXT.PERMISSION-ROLE' | translate}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer-alternative">
    <small class="text-red m-auto">{{errorMessage}}</small>
    <div class="">
      <button type="button" class="btn btn-dv btn-wide me-2" [disabled]="is_loading"
              (click)="validateCreateUserInput()">
        <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
        {{'COMMON.OK' | translate}}
      </button>
      <button type="button" class="btn btn-dv btn-clean btn-wide"
              (click)="revertBack()">{{'COMMON.CANCEL' | translate}}
      </button>
    </div>
  </div>
</ng-template>


<ng-template #recaptcha let-modal1>
  <div class="modal-header">
    <h5 class="m-0">{{ 'TEXT.RECAPTCHA-VALIDATION' | translate }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeRecaptchaModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <re-captcha (resolved)="recaptChaResolved($event , 'createUser')" [siteKey]="recaptchaSiteKey"></re-captcha>
  </div>
</ng-template>

<ng-template #approve let-modal>
  <div class="modal-header">
    <h5>{{'TEXT.APPROVE-ACCOUNT' | translate}}</h5>
  </div>
  <div class="modal-body">
    <small class="text-red">** {{ 'USER-MANAGEMENT.CONFIRM-BY-RECAPTCHA' | translate }}</small>
    <re-captcha (resolved)="recaptChaResolved($event , 'approve')" [siteKey]="recaptchaSiteKey"></re-captcha>
  </div>
  <div class="modal-footer-alternative">
    <div class="row no-gutters">
      <div class="col-12 text-right">
        <button type="button" class="btn btn-dv btn-clean btn-wide" [disabled]="is_loading" (click)="revertBack()">
          <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reject let-modal>
  <div class="modal-header">
    <h5>{{'TEXT.REJECT-ACCOUNT' | translate}}</h5>
  </div>
  <div class="modal-body">
    <label for="rejectReasonInput">
      {{'TEXT.REASON' | translate}}
    </label>
    <input
      id="rejectReasonInput"
      type="text"
      class="form-control"
      [(ngModel)]="rejectReason"
      placeholder="{{'TEXT.REASON' | translate}}"
    >
    <small class="text-red">** {{ 'USER-MANAGEMENT.CONFIRM-BY-RECAPTCHA' | translate }}</small>
    <re-captcha (resolved)="recaptChaResolved($event , 'reject')" [siteKey]="recaptchaSiteKey"></re-captcha>
  </div>
  <div class="modal-footer-alternative">
    <div class="row no-gutters">
      <div class="col-12 text-right">
        <button type="button" class="btn btn-dv btn-clean btn-wide" [disabled]="is_loading" (click)="revertBack()">
          <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<p-toast position="top-right"></p-toast>
<div class="header">
  <div class="row">
    <div class="col-md">
      <h3>{{'FEE-DEBIT-ACCOUNT.FEE-DEBIT-ACCOUNT' | translate}}</h3>
    </div>
  </div>
</div>

<div class="box-filter position-relative z-2">
  <app-filter-header [loading]="is_loading || downloadLoading" (submit)="searchButtonClicked()"
                     (clear)="clearFilter()"></app-filter-header>

  <div class="row">
    <div class="col p-5px">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          selectId="userRole"
          [options]="company"
          [(ngModel)]="selectedCompany"
          [autoDisplayFirst]="false"
          placeholder="{{'EMAIL-DASHBOARD.COMPANY' | translate}}"
          [style]="{'z-index': 3}"
        >
        </p-dropdown>
        <label for="company">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</label>
      </div>
    </div>
    <div class="col p-5px">
      <div class="dropdown-label-group">
        <p-dropdown
          class="dv-dropdown"
          selectId="accountNumber"
          [options]="accountNumberList"
          [(ngModel)]="selectedCompany"
          [autoDisplayFirst]="false"
          placeholder="{{'FEE-DEBIT-ACCOUNT.ACCOUNT-NUMBER' | translate}}"
          [style]="{'z-index': 3}"
        >
        </p-dropdown>
        <label for="year">{{'FEE-DEBIT-ACCOUNT.ACCOUNT-NUMBER' | translate}}</label>
      </div>
    </div>
  </div>

</div>

<div class="box-parent position-relative z-1">
  <app-custom-pagination
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    [totalItems]="totalItems"
    (pageChange)="pageChanged()"
    (optionChangeValue)="setPageSide($event)"
  >
    <div class="row align-items-center" *ngIf="canChecker()">
      <div class="col-auto">
        <button type="button" class="btn btn-dv btn-danger btn-wide"
                [disabled]="countIsCheckAll == 0 || countIsCheckAll > 1" (click)="openReject($event, reject)">
          {{'TEXT.REJECT-ACCOUNT' | translate}}
        </button>
      </div>
      <div class="col-auto">
        <button type="button" class="btn btn-dv btn-wide" [disabled]="!countIsCheckAll && !isCheckAll"
                (click)="openApprove($event, approve)">
          {{'TEXT.APPROVE-ACCOUNT' | translate}}
        </button>
      </div>
    </div>
  </app-custom-pagination>
  <div *ngIf="canMaker() || canViewer()" class="m-t-20">
    <p-table #tt class="table text-center mt-3" tableStyleClass="table" [loading]="is_loading"
             *ngIf="tableDataForMaker && !is_loading"
             [value]="companyList"
             [autoLayout]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col">{{ 'COMMON.TABLE.ORDER' | translate }}</th>
          <th scope="col" *ngIf="!canViewer()">{{ 'FEE-DEBIT-ACCOUNT.UPDATED-DATE' | translate }}</th>
          <th scope="col">{{ 'EMAIL-DASHBOARD.COMPANY' | translate }}</th>
          <th scope="col">{{ 'FEE-DEBIT-ACCOUNT.ACCOUNT-NUMBER' | translate }}</th>
          <th scope="col" *ngIf="!canViewer()">{{ 'FEE-DEBIT-ACCOUNT.STATUS' | translate }}</th>
          <th scope="col" *ngIf="!canViewer()">{{ 'FEE-DEBIT-ACCOUNT.REASON' | translate }}</th>
          <th scope="col" *ngIf="!canViewer()">{{ 'FEE-DEBIT-ACCOUNT.EDIT-FEE-DEBIT-ACCOUNT' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>{{index + 1}}</td>
          <td *ngIf="!canViewer()">{{data.request_updated_at || '-'}}</td>
          <td>{{ data.company_name_en || '-'}}</td>
          <td>{{ data.account_number || '-'}}</td>
          <td *ngIf="!data.request_status && !canViewer()">
            {{data.request_status || '-'}}
          </td>
          <td *ngIf="(data.request_status == 'Approved' || data.request_status == 'approved') && !canViewer()">
            {{'USER-MANAGEMENT.APPROVED' | translate}}
          </td>
          <td *ngIf="(data.request_status == 'Rejected' || data.request_status == 'rejected') && !canViewer()">
            {{'USER-MANAGEMENT.REJECTED' | translate}}
          </td>
          <td *ngIf="(data.request_status == 'Pending' || data.request_status == 'pending') && !canViewer()">
            {{'USER-MANAGEMENT.PENDING' | translate}}
          </td>
          <td *ngIf="!canViewer()">
            <fa-icon
              *ngIf="data.request_status == 'Rejected' || data.request_status == 'rejected'"
              [icon]="['fas', 'eye']"
              class="edit-btn"
              (click)="popRejectedReason($event, rejectedReason, data)"
            ></fa-icon>
          </td>
          <td *ngIf="!canViewer()">
            <fa-icon *ngIf="data.is_pending_request" [icon]="['fas', 'pen-to-square']" class="edit-btn div-disable"></fa-icon>
            <fa-icon
              *ngIf="!data.is_pending_request"
              [icon]="['fas', 'pen-to-square']"
              class="edit-btn"
              (click)="editAccountNumber($event, edit, data)"
            ></fa-icon>
          </td>
        </tr>
        <ng-template #edit let-modal>
          <div class="modal-header">
            <h5 class="m-0">{{ 'FEE-DEBIT-ACCOUNT.FEE-DEBIT-ACCOUNT' | translate}}</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mt-3">
              <div class="custom-label for">
                <input type="text" class="form-control" style="background-color: #FFFFFF" id="company"
                       [(ngModel)]="companyEdit" disabled
                       placeholder="{{'FEE-DEBIT-ACCOUNT.ACCOUNT-NUMBER' | translate}}" ng-required="accountNumber">
                <label for="company" class="text-left">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</label>
              </div>
            </div>

            <div class="mt-3">
              <div class="custom-label for">
                <input type="text" class="form-control" id="account number" [(ngModel)]="accountNumber"
                       placeholder="{{'FEE-DEBIT-ACCOUNT.ACCOUNT-NUMBER' | translate}}" ng-required="accountNumber"
                       maxlength="10">
                <label for="account number" class="text-left">{{'FEE-DEBIT-ACCOUNT.ACCOUNT-NUMBER' | translate}}</label>
              </div>
            </div>
            <div class="mt-3">
              <re-captcha #recaptchaRef (resolved)="resolve($event)" [siteKey]="recaptchaSiteKey"></re-captcha>
            </div>
          </div>
          <div class="modal-footer-alternative">
            <small class="text-red m-auto">{{errorMessage}}</small>
            <div>
              <button type="button" class="btn btn-dv btn-wide me-2"
                      [disabled]="!(accountNumber && recaptchaToken) || is_loading" (click)="createAccountNumber()">
                <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
                {{'COMMON.OK' | translate}}
              </button>
              <button type="button" class="btn btn-dv btn-clean btn-wide"
                      (click)="close()">{{'COMMON.CANCEL' | translate}}
              </button>
            </div>
          </div>
          <div>
          </div>
        </ng-template>
      </ng-template>
    </p-table>

    <div class="row m-t-20" *ngIf="is_loading">
      <div class="col-12 text-center">
        <fa-icon class="spinner-border m-r-5" [icon]="['fas','spinner']" size="2xl"></fa-icon>
      </div>
    </div>
  </div>

  <div *ngIf=canChecker() class="m-t-20">
    <p-table #tt class="text-center" tableStyleClass="table" [loading]="is_loading"
             *ngIf="tableDataForChecker && !is_loading"
             [value]="tableDataForChecker" [customSort]="true"
             [autoLayout]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th scope="col" style="width: 5%;" *ngIf="tableDataForChecker.length">
            <p-checkbox
              class="dv-checkbox"
              binary="true"
              [(ngModel)]="isCheckAll"
              (onChange)="checkAll($event)"
            ></p-checkbox>
          </th>
          <th scope="col" style="width: 5%;">{{'COMMON.TABLE.ORDER' | translate}}</th>
          <th scope="col">{{ 'FEE-DEBIT-ACCOUNT.UPDATED-DATE' | translate }}</th>
          <th scope="col">{{'EMAIL-DASHBOARD.COMPANY' | translate}}</th>
          <th scope="col">{{ 'FEE-DEBIT-ACCOUNT.ACCOUNT-NUMBER' | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-data let-index="rowIndex">
        <tr>
          <td>
            <p-checkbox
              class="dv-checkbox"
              [(ngModel)]="data.status"
              binary="true"
              (onChange)="checkBox($event)"
            ></p-checkbox>
          </td>
          <td>{{((currentPage - 1) * itemsPerPage) + index + 1}}</td>
          <td>{{data.updated_at || '-'}}</td>
          <td>{{data.company_name_en || data.old_username}}</td>
          <td>{{data.account_number}}</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="row m-t-20" *ngIf="!tableDataForChecker && !isLoading">
      <div class="col-12 text-center">
        <h5>{{'COMMON.DATA-NOT-FOUND' | translate}}</h5>
      </div>
    </div>
  </div>
</div>
<ng-template #reject let-modal>
  <div class="modal-header">
    <h5>{{'TEXT.REJECT-ACCOUNT' | translate}}</h5>
  </div>
  <div class="modal-body">
    <label for="rejectReasonInput">
      {{'TEXT.REASON' | translate}}
    </label>
    <input
      id="rejectReasonInput"
      type="text"
      class="form-control"
      [(ngModel)]="rejectReason"
      placeholder="{{'TEXT.REASON' | translate}}"
    >
    <small class="text-red">** {{ 'USER-MANAGEMENT.CONFIRM-BY-RECAPTCHA' | translate }}</small>
    <re-captcha (resolved)="recaptChaResolved($event , 'reject')" [siteKey]="recaptchaSiteKey"></re-captcha>
  </div>
  <div class="modal-footer-alternative">
    <div class="row no-gutters">
      <div class="col-12 text-right">
        <button type="button" class="btn btn-dv btn-clean btn-wide" [disabled]="is_loading" (click)="close()">
          <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #approve let-modal>
  <div class="modal-header">
    <h5>{{'TEXT.APPROVE-ACCOUNT' | translate}}</h5>
  </div>
  <div class="modal-body">
    <small class="text-red">** {{ 'USER-MANAGEMENT.CONFIRM-BY-RECAPTCHA' | translate }}</small>
    <re-captcha (resolved)="recaptChaResolved($event , 'approve')" [siteKey]="recaptchaSiteKey"></re-captcha>
  </div>
  <div class="modal-footer-alternative">
    <div class="row no-gutters">
      <div class="col-12 text-right">
        <button type="button" class="btn btn-dv btn-clean btn-wide" [disabled]="is_loading" (click)="close()">
          <fa-icon *ngIf="is_loading" class="spinner-border m-r-5" [icon]="['fas','spinner']"></fa-icon>
          {{'COMMON.CANCEL' | translate}}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rejectedReason let-modal>
  <div class="modal-header">
    <h3 class="m-0">{{'USER-MANAGEMENT.REJECT-REASON' | translate}}: </h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-model">
    <div class="row bg-model">
      <div class="col-12 text-center bg-model">
        <p-table [value]="rejectedReasonModal" dataKey="value" width="100%">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" style="width: 25%;">{{'USER-MANAGEMENT.UPDATED_DATE' | translate}}</th>
              <th scope="col"  style="width: 50%;">{{'USER-MANAGEMENT.REJECT-REASON' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-data>
            <tr>
              <td>
                {{data.request_updated_at}}
              </td>
              <td>
                {{data.rejected_reason}}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="text-center">
      <button type="button" class="btn btn-dv btn-clean btn-wide"
              (click)="close()">{{'COMMON.CLOSE' | translate}}
      </button>
    </div>
  </div>
</ng-template>

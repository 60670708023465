<div class="header">
  <h3 >{{ 'NEW-TRANSLATE.DATA-PURGE.LIST.TITLE' | translate }}</h3>
  <h5>{{ 'NEW-TRANSLATE.COMMON.TOTAL-{{value}}-FILES' | translate: { value: ( totalItems | number ) } }}</h5>
</div>

<div class="data-purge-list-container d-flex flex-column flex-md-row gap-2">
  <div class="data-purge-list-datatables-wrapper">
    <div class="criteria-search-container">
      <app-filter-header
        [loading]="isLoading || isLoadingRows || isLoadingOther"
        (submit)="onSearch()"
        (clear)="onClear()"
      />
      <div class="criteria-search-wrapper">
        <form [formGroup]="formDataPurgeList" class="form-wrapper">
          <div class="row g-2">
            <div class="col-12">
              <div
                class="dropdown-label-group"
                [ngClass]="{ 'is-invalid': submittedDataPurgeList && formDataPurgeList.controls['period_years'].errors }"
              >
                <p-dropdown
                  class="dv-dropdown"
                  formControlName="period_years"
                  [loading]="isLoading || isLoadingRows || isLoadingOther"
                  [options]="periodYears"
                  optionValue="value"
                  selectId="periodYearsSelect"
                  [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.SELECT-PERIOD-OF-TIME' | translate"
                >
                  <ng-template let-item pTemplate="selectedItem">
                    <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    <div>{{ language === 'th' ? item.name_th : item.name_en }}</div>
                  </ng-template>
                </p-dropdown>
                <label for="periodYearsSelect">{{ 'NEW-TRANSLATE.COMMON.PERIOD-OF-TIME' | translate }}</label>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="datatables-container">
      <app-custom-pagination
        [(currentPage)]="pages"
        [(itemsPerPage)]="limitRow"
        [totalItems]="collectionSize"
        [isLoading]="isLoading || isLoadingRows || isLoadingOther"
        (pageChange)="pageChanged()"
        (optionChangeValue)="limitRowChanged($event)"
      >
        <div class="col-auto d-flex gap-2 mt-3 mt-lg-0">
          <button
            class="btn btn-dv btn-wide"
            data-testid="purgeFilesButton"
            [disabled]="!rows || rows.length === 0 || !selectedRows || selectedRows.length === 0 || isLoadingRows || isLoadingOther || isLoading"
            id="purgeFilesButton"
            type="button"
            [title]="'NEW-TRANSLATE.DOWNLOAD.DOWNLOAD-EXCEL' | translate"
            (click)="onPurgeFiles()"
          >
            {{ 'NEW-TRANSLATE.DATA-PURGE.LIST.PURGE-FILES' | translate }}
          </button>
        </div>
      </app-custom-pagination>
      <app-datatables
        #datatables
        [collectionSize]="collectionSize"
        [columns]="columns"
        [isLoadingRows]="isLoadingRows"
        [isLoadingOther]="isLoadingOther"
        [limitRow]="limitRow"
        [pages]="pages"
        [rows]="rows"
        [(selectedRows)]="selectedRows"
        tableId="dataPurgeListDatatable"
      />
    </div>
  </div>
  <div class="data-purge-list-storage-wrapper">
    <div class="criteria-search-container">
      <div class="d-flex gap-2 flex-wrap">
        <div class="flex-1">
          <h5 class="m-0">{{ 'NEW-TRANSLATE.DATA-PURGE.LIST.STORAGE' | translate }}</h5>
        </div>
        <div class="criteria-search-wrapper">
          <form [formGroup]="formStorage" class="form-wrapper">
            <div
              class="dropdown-label-group"
              [ngClass]="{ 'is-invalid': submittedStorage && formStorage.controls['year'].errors }"
            >
              <p-dropdown
                class="dv-dropdown"
                formControlName="year"
                selectId="yearSelect"
                [options]="years"
                [loading]="isLoading || isLoadingOther || isLoadingStorage"
                [placeholder]="'NEW-TRANSLATE.PLACEHOLDER.PLEASE-SELECT' | translate"
                (onChange)="loadStorage()"
              >
              </p-dropdown>
              <label for="yearSelect">{{ 'NEW-TRANSLATE.COMMON.YEAR' | translate }}</label>
            </div>
          </form>
        </div>
      </div>
      @if (isDataPurgePending) {
        <div class="text-end mt-3">
          <a
            class="btn btn-dv btn-wide"
            id="purgePendingFilesLink"
            data-testid="purgePendingFilesLink"
            [routerLink]="['/purge/result']"
            [title]="'NEW-TRANSLATE.DATA-PURGE.LITS.PENDING-PURGE-FILES' | translate"
          >
            {{ 'NEW-TRANSLATE.DATA-PURGE.LIST.PENDING-PURGE-FILES' | translate}}
          </a>
        </div>
      }
    </div>
    <div class="summary-container">
      @if (!isLoadingStorage) {
        @if (storageSummaryList.length > 0) {
          <ul class="summary-wrapper">
            @for (storageSummaryItem of storageSummaryList; track storageSummaryItem) {
              <li class="summary-item">
                <div class="summary-title-item">
                  {{ language === 'th' ? storageSummaryItem.name_th : storageSummaryItem.name_en }}
                </div>
                <div class="summary-total-item text-truncate">
                  {{ storageSummaryItem.value | formatByte: 3 }}
                </div>
              </li>
          
            }
          </ul>
       
        } @else {
          <div class="text-center">
            <label class="m-0">{{ 'NEW-TRANSLATE.DATA-PURGE.LIST.NO-STORAGE-DATA' | translate }}</label>
          </div>
        }
      } @else {
        <div class="text-center">
          <fa-icon
            class="spinner-border"
            [icon]="['fas','spinner']"
            size="lg"
          ></fa-icon>
        </div>
      }
    </div>
  </div>
</div>
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, map, of, takeUntil, zip } from 'rxjs';
import { HttpClientService } from '../../../../service/http-client.service';
import { HandleModalService, HandleTranslateService, UtilitiesService } from '../../../../shared/services';
import { DatatableCustomColumnModel, LanguageType, LookupModel, ResponseMessageCompanyModel } from '../../../../shared/interfaces';
import { ApiService } from '../../../../service/api.service';
import { SEND_TO_RD_CONFIGURATION } from '../../setting.constant';
import { environment } from '../../../../../environments/environment';
import { PayloadSettingReportModel, ResponseMessageSettingReportModel, SettingReportModel } from '../../setting.interface';
import { DatatablesComponent } from '../../../../shared/components/datatables/datatables.component';

@Component({
  selector: 'app-setting-report',
  templateUrl: './setting-report.component.html',
  styleUrl: './setting-report.component.scss'
})
export class SettingReportComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public sendToRdConfigurations: any[] = [];

  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public rows: SettingReportModel[] = [];
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'no',
      label: 'NEW-TRANSLATE.COMMON.NO',
      name: 'no',
      type: 'no',
      width: '80px',
      minWidth: '80px'
    },
    { 
      id: 'companyName',
      label: 'NEW-TRANSLATE.COMMON.COMPANY',
      name: 'company_name_th',
      type: 'string',
      width: '100%',
      minWidth: '300px'
    },
    { 
      id: 'sendToRd',
      label: 'NEW-TRANSLATE.SETTING.SENT-TO-RD',
      name: 'service_provider_option',
      type: 'multi-condition',
      width: '200px',
      minWidth: '200px',
      multiConditions: [
        { value: 'NEW-TRANSLATE.SETTING.AUTO-SEND', key: 2 },
        { value: 'NEW-TRANSLATE.SETTING.MANUAL-SEND', key: 1 },
        { value: '-', key: 0 }
      ],
      frozen: true,
      alignFrozen: 'right',
      cellStyle: 'color: #633BA4; font-weight: bold;'
    }
  ]

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    company: new FormControl(null),
    send_to_rd_configuration: new FormControl(null)
  });

  public isLoading: boolean = true;
  public isLoadingRows: boolean = true;

  constructor(
    private handleTranslateService: HandleTranslateService,
    private httpClientService: HttpClientService,
    private handleModalService: HandleModalService,
    private apiService: ApiService,
    private utilitiesService: UtilitiesService
  ) {
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadSendToRdConfiguration()
    )
    .pipe()
    .subscribe({
      next: ([ companies, sendToRdConfiguration ]) => {
        this.companies = companies;
        this.companies.unshift(this.allSelect);
        this.sendToRdConfigurations = sendToRdConfiguration;
        this.sendToRdConfigurations.unshift(this.allSelect);
        this.isLoading = false;
        this.loadSettingReport();
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    })
  }

  private loadSendToRdConfiguration(): Observable<LookupModel[]> {
    return of(Object.assign([] as LookupModel[] ,SEND_TO_RD_CONFIGURATION));
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [ ...newRes.results ].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id, tax_id: x.tax_id }
          });
        })
      );
  }

  public onSearch(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.pages = 1;
    this.datatablesElement?.reset();
    this.loadSettingReport();
  }

  public onClear(): void {
    const formControl = this.form.controls;
          formControl['company'].setValue(null);
          formControl['send_to_rd_configuration'].setValue(null);
    this.pages = 1;
    this.submitted = false;
    this.loadSettingReport();
  }

  public pageChanged(): void {
    this.loadSettingReport();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadSettingReport();
  }

  private loadSettingReport(): void {
    this.rows = [];
    this.isLoadingRows = true;
    const formValue = this.form.value;
    const payload: PayloadSettingReportModel = {
      service_provider_option: formValue['send_to_rd_configuration'] ?? undefined,
      tax_id: formValue['company']?.tax_id ?? undefined
    }
    const queryParams = this.utilitiesService.createQueryParams(payload);
    this.httpClientService
      .get(`${ environment.apiURL }/api/company_setting_list?page=${ this.pages }&page_size=${ this.limitRow }${ queryParams ? '&' + queryParams : '' }`)
      .pipe(
        takeUntil(this.unsubscribe$),
        map(x => x as ResponseMessageSettingReportModel)
      )
      .subscribe({
        next: (res) => {
          this.collectionSize = res.count;
          this.rows = res.results ?? [];
          this.isLoadingRows = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

}

import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../service/authentication.service';
import { HandleModalService, HandleTranslateService, UtilitiesService } from '../../../../shared/services';
import { Observable, Subject, map, of, takeUntil, zip } from 'rxjs';
import { DatatablesComponent } from '../../../../shared/components/datatables/datatables.component';
import { DatatableCustomColumnModel, LanguageType, PeriodYearsModel } from '../../../../shared/interfaces';
import { DataPurgeListModel, PayloadDataPurgeStorageModel, PayloadLoadDataPurgeListModel, ResponseMessageDataPurgeListModel, ResponseMessageDataPurgePendingModel, StorageSummaryModel } from '../../data-purge.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClientService } from '../../../../service/http-client.service';
import { environment } from '../../../../../environments/environment';
import moment from 'moment';
import { MONTHS } from '../../../../shared/services/shared.constant';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-data-purge-list',
  templateUrl: './data-purge-list.component.html',
  styleUrl: './data-purge-list.component.scss'
})
export class DataPurgeListComponent {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public periodYears: PeriodYearsModel[] = [];
  public years: number[] = [];

  public storageSummaryList: StorageSummaryModel[] = [];
  public isDataPurgePending: boolean = false;

  private currentDate: Date = new Date();

  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public rows: DataPurgeListModel[] = [];
  public selectedRows: DataPurgeListModel[] = [];
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'checkbox',
      label: '',
      name: '',
      type: 'checkbox',
      width: '4rem',
      minWidth: '4rem'
    },
    {
      id: 'fileName',
      label: 'NEW-TRANSLATE.COMMON.FILE-NAME',
      name: 'csv_name',
      type: 'string',
      defaultValue: '-',
      width: '300px',
      minWidth: '300px',
      cellClass: 'text-start text-break'
    },
    {
      id: 'importDate',
      label: 'NEW-TRANSLATE.COMMON.IMPORT-DATE',
      name: 'created_at',
      type: 'date',
      width: '135px',
      minWidth: '135px',
      format: 'DD/MM/YYYY'
    },
    {
      id: 'dueDate',
      label: 'NEW-TRANSLATE.COMMON.DUE-DATE',
      name: 'due_date',
      type: 'date',
      width: '135px',
      minWidth: '135px',
      format: 'DD/MM/YYYY'
    },
  ];

  public submittedDataPurgeList: boolean = false;
  public formDataPurgeList: FormGroup = new FormGroup({
    period_years: new FormControl(null, Validators.required)
  });

  public submittedStorage: boolean = false;
  public formStorage: FormGroup = new FormGroup({
    year: new FormControl(null, Validators.required)
  });

  public totalItems: number = 0;

  public isLoading: boolean = false;
  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;
  public isLoadingStorage: boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private handleTranslateService: HandleTranslateService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private handleModalService: HandleModalService
  ) {
    this.checkPermission();
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private checkPermission(): void {
    if (!this.authenticationService.isDataPurgePermission()) {
      this.router.navigate(['/']);
    }
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadPeriodYears(),
      this.loadYear(),
      this.loadDataPurgePending()
    ).pipe()
    .subscribe({
      next: ([ periodYears, years, isDataPurgePending ]) => {
        this.isDataPurgePending = isDataPurgePending;
        this.years = years;
        this.formStorage.controls['year'].setValue(this.years[this.years.length - 1]);
        this.periodYears = periodYears;
        this.formDataPurgeList.controls['period_years'].setValue(this.periodYears[0].value);
        this.loadDataPurgeList();
        this.loadStorage();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    })
  }

  private loadYear(): Observable<number[]>{
    const currentYear = this.currentDate.getFullYear();
    const years = Array.from({ length: currentYear - 2010 + 1 }, (_, i) => 2010 + i);
    return of(years);
  }

  private loadPeriodYears(): Observable<PeriodYearsModel[]> {
    const currentMoment = moment(this.currentDate);
    const periodYears = Array.from({ length: 7 }, (_, i) => {
                          const year = 8 - i;
                          return {
                            name_th: year === 8 ? 'มากกว่า 7 ปี' : `${year} ปี`,
                            name_en: year === 8 ? 'Over 7 years' : `${year} years`,
                            value: currentMoment.clone().subtract(year, 'years').format('YYYY-MM-DD')
                          };
                        });
    periodYears.push({
      name_en: 'All Files',
      name_th: 'เอกสารทั้งหมด',
      value: currentMoment.format('YYYY-MM-DD')
    });
    return of(periodYears)
  }

  private loadDataPurgeList(): void {
    this.rows = [];
    this.selectedRows = [];
    this.isLoadingRows = true;
    const payload = this.getPayloadLoadDataPurgeList();
    const queryParams = this.utilitiesService.createQueryParams(payload);
    this.httpClientService
      .get(`${ environment.apiURL }/api/tax_imports/${ queryParams ? '?' + queryParams : '' }`)
        .pipe(
          takeUntil(this.unsubscribe$),
          map((res) => {
            const newRes = res as ResponseMessageDataPurgeListModel;
            const newResult = [ ...newRes.results ].map(x => {
              const value = { ...x };
                    value.due_date = moment(x.created_at).add(7, 'years').toISOString()
              return value;
            });
            newRes.results = newResult;
            return newRes;
          })
        )
        .subscribe({
          next: (res) => {
            this.collectionSize = res.count ?? 0;
            this.totalItems = res.count ?? 0;
            this.rows = res.results ?? [];
            this.isLoadingRows = false;
          },
          error: (err) => {
            console.error(err);
            this.isLoadingRows = false;
            if (!this.handleModalService.hasModal('failedModal')) {
              const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
              this.handleModalService.connectFailedModal(errorMessage);
            }
          }
        });
  }

  private getPayloadLoadDataPurgeList(): PayloadLoadDataPurgeListModel {
    const formValue = this.formDataPurgeList.value;
    const payload: PayloadLoadDataPurgeListModel = {
      page: this.pages,
      page_size: this.limitRow,
      year_lte: formValue['period_years'],
      is_purged_selected: 'False'
    };
    return payload;
  }

  public loadStorage(): void {
    this.submittedStorage = true;
    if (this.formStorage.invalid) return;
    this.isLoadingStorage = true;
    const payload = this.getPayloadStorage();
    const queryParams = this.utilitiesService.createQueryParams(payload);
    this.httpClientService
      .get(`${ environment.apiURL }/api/tax_imports/${ queryParams ? '?' + queryParams : '' }`)
      .pipe(
        takeUntil(this.unsubscribe$),
        map((res) => {
          const newRes = res as DataPurgeListModel[];
          const newResult = this.calculateMonthlyData(newRes);
          return newResult;
        })
      )
      .subscribe({
        next: (res) => {
          this.storageSummaryList = res;
          this.isLoadingStorage = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingStorage = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  private loadDataPurgePending(): Observable<boolean> {
    const params = new HttpParams().set('is_purged_selected', 'True');
    return this.httpClientService
            .get(`${ environment.apiURL }/api/tax_imports/`, params)
            .pipe(
              takeUntil(this.unsubscribe$),
              map((res) => {
                const newRes = res as ResponseMessageDataPurgePendingModel;
                return newRes.results.length > 0;
              })
            );
  }

  private getPayloadStorage(): PayloadDataPurgeStorageModel {
    const formValue = this.formStorage.value;
    const payload: PayloadDataPurgeStorageModel = {
      none_pagination: 'True',
      year: formValue['year'] ?? ''
    }
    return payload;
  }
  
  private calculateMonthlyData(data: DataPurgeListModel[]): StorageSummaryModel[] {
    const monthlyData = data
                          .filter(x => x.file_size)
                          .map(x => ({
                            month: + moment(x.created_at).format('MM'),
                            file_size: x.file_size
                          }))
                          .reduce((acc, curr) => {
                            acc[curr.month] = (acc[curr.month] || 0) + curr.file_size;
                            return acc;
                          }, {} as Record<number, number>);
  
    return MONTHS.map(x => ({
            value: monthlyData[x.id as number] || 0,
            name_th: x.name_th,
            name_en: x.name_en
          }))
          .filter(x => x.value > 0);
  }

  public onSearch(): void {
    this.submittedDataPurgeList = true;
    if (this.formDataPurgeList.invalid) return;
    this.submittedDataPurgeList = false;
    this.pages = 1;
    this.datatablesElement?.reset();
    this.loadDataPurgeList();
  }

  public onClear(): void {
    this.formDataPurgeList.controls['period_years'].setValue(this.periodYears[0].value);
    this.pages = 1;
    this.submittedDataPurgeList = false;
    this.loadDataPurgeList();
  }

  public pageChanged(): void {
    this.loadDataPurgeList();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadDataPurgeList();
  }

  public onPurgeFiles(): void {
    if (!this.selectedRows || this.selectedRows.length === 0) return;
    this.isLoadingOther = true;
    const data = {
      tax_data_list: this.selectedRows.map(x => x.id),
      is_purged_selected: true
    };
    this.httpClientService
      .patch(`${ environment.apiURL }/api/patch_tax_import/`, data)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe({
        next: () => {
          this.router.navigate(['purge/result']);
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

}

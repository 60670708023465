import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable, Subject, Subscription, distinctUntilChanged, interval, map, of, startWith, switchMap, takeUntil, zip } from 'rxjs';
import { LanguageType, LookupModel, DocumentTypeModel, BranchModel, DatatableCustomColumnModel, ResponseMessageBranchModel, ResponseMessageCompanyModel, RowActionEventModel, SortType } from '../../shared/interfaces';
import { Router } from '@angular/router';
import { ApiService } from '../../service/api.service';
import { AuthenticationService } from '../../service/authentication.service';
import { HttpClientService } from '../../service/http-client.service';
import { HandleTranslateService, HandleModalService, UtilitiesService, MasterDataService } from '../../shared/services';
import { BulkTaxReportModel, PayloadGenerateBulkTaxReportModel, PayloadLoadBulkTaxReportModel, ResponseMessageBulkTaxReportModel } from './bulk-tax-report.interface';
import { environment } from '../../../environments/environment';
import moment from 'moment';
import { DatatablesComponent } from '../../shared/components/datatables/datatables.component';

@Component({
  selector: 'app-bulk-tax-report',
  templateUrl: './bulk-tax-report.component.html',
  styleUrl: './bulk-tax-report.component.scss'
})
export class BulkTaxReportComponent implements OnInit, OnDestroy {

  @ViewChild('datatables') private datatablesElement: DatatablesComponent | undefined;

  private unsubscribe$: Subject<void> = new Subject();

  public language: LanguageType;

  public allSelect: LookupModel = {
    id: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public allDocumentType: DocumentTypeModel = {
    type: null,
    name_th: 'ทั้งหมด',
    name_en: 'All'
  };
  public companies: LookupModel[] = [];
  public branches: LookupModel[] = [];
  private defaultBranches: BranchModel[] = [];
  public documentType: DocumentTypeModel[] = [];

  private bulkTaxReportSubscription: Subscription | null = null;

  private currentDate: Date = new Date();
  public maxRange: number = 93;

  public pages: number = 1;
  public limitRow: number = 10;
  public collectionSize: number = 0;
  public sortBy: string | undefined;
  public sortMode: SortType | undefined;
  public rows: BulkTaxReportModel[] = [];
  public columns: DatatableCustomColumnModel[] = [
    {
      id: 'no',
      label: 'NEW-TRANSLATE.COMMON.NO',
      name: 'no',
      type: 'no',
      width: '80px',
      minWidth: '80px'
    },
    {
      id: 'period',
      label: 'NEW-TRANSLATE.COMMON.PERIOD',
      name: 'period',
      type: 'string',
      width: '250px',
      minWidth: '250px',
      sort: true
    },
    {
      id: 'companyName',
      label: 'NEW-TRANSLATE.COMMON.COMPANY',
      name: 'company_name',
      type: 'string',
      width: '300px',
      minWidth: '300px',
      defaultValue: 'NEW-TRANSLATE.COMMON.ALL-COMPANY',
      sort: true
    },
    {
      id: 'branchName',
      label: 'NEW-TRANSLATE.COMMON.BRANCH-NAME',
      name: 'branch_fullname',
      type: 'string',
      width: '300px',
      minWidth: '300px',
      defaultValue: 'NEW-TRANSLATE.COMMON.ALL-BRANCH',
      sort: true
    },
    {
      id: 'documentType',
      label: 'NEW-TRANSLATE.COMMON.DOCUMENT-TYPE',
      name: 'rd_document_type',
      type: 'document-type',
      width: '150px',
      minWidth: '150px',
      sort: true
    },
    {
      id: 'requqestDate',
      label: 'NEW-TRANSLATE.COMMON.REQUEST-DATE',
      name: 'created_at',
      type: 'date',
      format: 'DD/MM/YYYY',
      width: '120px',
      minWidth: '120px',
      defaultValue: '-',
      sort: true
    },
    {
      id: 'expireDate',
      label: 'NEW-TRANSLATE.COMMON.EXPIRE-DATE',
      name: 'expire_at',
      type: 'date',
      format: 'DD/MM/YYYY',
      width: '120px',
      minWidth: '120px',
      defaultValue: '-',
      sort: true
    },
    {
      id: 'status',
      label: 'NEW-TRANSLATE.COMMON.STATUS',
      name: 'bulk_tax_status',
      type: 'status',
      width: '140px',
      minWidth: '140px',
      sort: true
    },
    {
      id: 'download',
      label: 'NEW-TRANSLATE.COMMON.ALL-TAX-REPORT',
      name: 'download',
      type: 'action-icon',
      width: '90px',
      minWidth: '90px',
      lazyLoading: true,
      format: 'file-alt',
      actionIconOptions: {
        style: 'color: #633BA4;',
        condition: 'is_download'
      }
    },
  ];

  public submitted: boolean = false;
  public form: FormGroup = new FormGroup({
    corporate_id: new FormControl(null),
    corporate_branch_id: new FormControl(null),
    rd_document_type: new FormControl(undefined, [ Validators.required ]),
    docu_date_period: new FormControl(undefined, [ Validators.required ])
  });

  public isLoading: boolean = true;
  public isLoadingRows: boolean = false;
  public isLoadingOther: boolean = false;
  public isLoadingGenerate: boolean = false;

  public isFirstLoadRows: boolean = false;

  public isViewerImpAndHelp: boolean = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private handleTranslateService: HandleTranslateService,
    private apiService: ApiService,
    private handleModalService: HandleModalService,
    private httpClientService: HttpClientService,
    private utilitiesService: UtilitiesService,
    private masterDataService: MasterDataService
  ) {
    this.checkPermission();
    this.settingRolePermission();
    this.filterColumn();
    this.subscribeToServices();
  }

  ngOnInit(): void {
    this.initialSetting();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.bulkTaxReportSubscription?.unsubscribe();
  }

  private subscribeToServices(): void {
    this.handleTranslateService.language?.pipe(takeUntil(this.unsubscribe$)).subscribe(x => this.language = x);
  }

  private checkPermission(): void {
    if (!this.authenticationService.isBulkTaxReportPermission()) {
      this.router.navigate(['/']);
    }
  }

  private settingRolePermission(): void {
    this.isViewerImpAndHelp = this.authenticationService.isViewerImpAndHelp();
  }

  private filterColumn(): void {
    let columns = [...this.columns];
    const removeColumns = (colsToRemove: string[]) => columns = columns.filter(x => !colsToRemove.includes(x.id));
    if (this.authenticationService.isViewerImpAndHelp()) {
      removeColumns([ 'download' ]);
    } else {
      removeColumns([ 'companyName' ]);
    }
    this.columns = columns;
  }

  private initialSetting(): void {
    this.isLoading = true;
    zip(
      this.loadCompany(),
      this.loadBranch(),
      this.loadDocumentType()
    )
    .pipe()
    .subscribe({
      next: ([ companies, branches, documentType ]) => {
        this.documentType = documentType;
        this.companies = companies;
        this.defaultBranches = branches.results;
        if (!this.authenticationService.isSCBBCM() && this.companies.length === 1) {
          const defaultCompany = this.companies[0].id;
          this.form.controls['corporate_id'].setValue(defaultCompany, { emitEvent: false });
          this.filterBranches(defaultCompany);
        } else {
          this.companies.unshift(this.allSelect);
          this.filterBranches();
        }
        this.initialSettingForViewerImpAndHelp();
        this.loadBulkTaxReport();
        this.isLoading = false;
      },
      error: (err) => {
        console.error(err);
        if (!this.handleModalService.hasModal('failedModal')) {
          const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
          this.handleModalService.connectFailedModal(errorMessage);
        }
      }
    });
  }

  private initialSettingForViewerImpAndHelp(): void {
    if (this.isViewerImpAndHelp) {
      this.setPeriodDocumentDate();
      this.form.controls['rd_document_type'].setValue(null);
      this.form.controls['rd_document_type'].clearValidators();
      this.form.controls['rd_document_type'].updateValueAndValidity();
    } else {
      this.form.controls['rd_document_type'].setValue(undefined);
      this.documentType = [ ...this.documentType ].filter(x => x.type !== null);
    }
  }

  public setPeriodDocumentDate(): void {
    this.form.controls['docu_date_period'].setValue([ moment(this.currentDate).subtract(this.maxRange, 'days').toDate(), this.currentDate ]);
  }

  public filterBranches(companyId?: number | string | null) {
    this.form.controls['corporate_branch_id'].setValue(null);
    this.branches = Object.assign([] as BranchModel[], this.defaultBranches)
                      .filter(x => x.corporate === companyId || !companyId)
                      .map(x => {
                        const filterName = x.full_name;
                        return { name_th: filterName, name_en: filterName, id: x.id };
                      });
    this.branches.unshift(this.allSelect);          
  }

  private loadCompany(): Observable<LookupModel[]> {
    return this.apiService
      .getCompany()
      .pipe(
        takeUntil(this.unsubscribe$),
        map(res => {
          const newRes =  res as ResponseMessageCompanyModel;
          return [...newRes.results].map(x => {
            return { name_th: x.name, name_en: x.name, id: x.id }
          });
        })
      );
  }

  private loadBranch(): Observable<ResponseMessageBranchModel> {
    return this.apiService
        .getBranch()
        .pipe(
          takeUntil(this.unsubscribe$),
          map(x => x as ResponseMessageBranchModel)
        );
  }

  private loadDocumentType(): Observable<DocumentTypeModel[]> {
    return of(this.masterDataService.getDocumentType())
            .pipe(
              map(res => {
                const newRes: DocumentTypeModel[] = res.map(x => {
                        return { type: x.type, name_th: x.name_th, name_en: x.name_en }
                      });
                      newRes.unshift(this.allDocumentType);
                return newRes;
              })
            );
  }

  public onSubmit(): void {
    if (this.isViewerImpAndHelp) {
      this.onSearch();
    } else {
      this.onGenerate();
    }
  }

  private onSearch(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.pages = 1;
    this.sortBy = undefined;
    this.sortMode = undefined;
    this.datatablesElement?.reset();
    this.loadBulkTaxReport();
  }

  private onGenerate(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.submitted = false;
    this.isLoadingGenerate = true;
    const payload = this.getPayloadGenerateBulkTaxReport();
    this.httpClientService
      .post(`${ environment.apiURL }/api/bulk_tax_report/`, payload)
      .pipe(
        map(x => x)
      )
      .subscribe({
        next: () => {
          this.handleModalService.successModal({
            title: 'NEW-TRANSLATE.BULK-TAX-REPORT.SUCCESSFULLY',
            cancelButton: {
              id: 'closeModalButton',
              show: true,
              name: 'NEW-TRANSLATE.COMMON.CLOSE',
              class: 'btn-dv btn-wide'
            }
          });
          this.loadBulkTaxReport();
          this.isLoadingGenerate = false;
        },
        error: (err) => {
          console.error(err);
          this.isLoadingGenerate = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  public onClear(): void {
    const defaultCorportateId = this.companies.length === 1 ? this.companies[0].id : null;
    const formControl = this.form.controls;
          formControl['corporate_id'].setValue(defaultCorportateId);
          formControl['corporate_branch_id'].setValue(null);
    this.filterBranches(defaultCorportateId);
    if (this.isViewerImpAndHelp) {
      this.setPeriodDocumentDate();
      formControl['rd_document_type'].setValue(null);
      this.pages = 1;
      this.submitted = false;
      this.sortBy = undefined;
      this.sortMode = undefined;
      this.datatablesElement?.reset();
      this.loadBulkTaxReport();
    } else {
      formControl['rd_document_type'].setValue(undefined);
      formControl['docu_date_period'].setValue(undefined);
    }
    this.submitted = false;
  }

  public pageChanged(): void {
    this.loadBulkTaxReport();
  }

  public limitRowChanged(event: number): void {
    this.limitRow = event;
    this.pages = 1;
    this.loadBulkTaxReport();
  }

  public onRowActionEvent(rowEvent: RowActionEventModel): void {
    if (rowEvent.action === 'download') {
      this.onDownload(rowEvent);
    }
  }

  public onSortEvent(): void {
    this.loadBulkTaxReport();
  }

  private loadBulkTaxReport(): void {
    if (this.bulkTaxReportSubscription) {
      this.bulkTaxReportSubscription.unsubscribe();
    }
    this.isLoadingRows = true;
    const payload = this.getPayloadLoadBulkTaxReport();
    const queryParams = this.utilitiesService.createQueryParams(payload);
    this.bulkTaxReportSubscription = interval(5000)
      .pipe(
        takeUntil(this.unsubscribe$),
        startWith(0),
        switchMap(() => 
          this.httpClientService.get(`${ environment.apiURL }/api/bulk_tax_report/?page=${ this.pages }&page_size=${ this.limitRow }${ queryParams ? '&' + queryParams : '' }`)
        ),
        map((res) => {
          const newRes = res as ResponseMessageBulkTaxReportModel;
          const newResult = [ ...newRes.results ].map(x => {
            const value = Object.assign({} as BulkTaxReportModel, x);
            value.bulk_tax_status = this.getStatus(value.bulk_tax_status);
            value.header_issue_date_start = moment(value.header_issue_date_start, 'YYYY-MM-DD').format('DD/MM/YYYY');
            value.header_issue_date_end = moment(value.header_issue_date_end, 'YYYY-MM-DD').format('DD/MM/YYYY');
            value.is_download = value.bulk_tax_status === 2;
            return value;
          });
          newRes.results = newResult;
          return newRes;
        }),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev.results) === JSON.stringify(curr.results))
      )
      .subscribe({
        next: (res) => {
          this.collectionSize = res.count;
          this.rows = res.results ?? [];
          this.isLoadingRows = false;
          if (this.isFirstLoadRows) {
            this.isLoadingRows = false;
            this.isFirstLoadRows = false;
          }
        },
        error: (err) => {
          console.error(err);
          this.isLoadingRows = false;
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  private onDownload(rowEvent: RowActionEventModel): void {
    this.isLoadingOther = true;
    this.utilitiesService.toggleLoadingCell(rowEvent.targetElement, rowEvent.loadingElement);
    const data: BulkTaxReportModel = rowEvent.row;
    this.httpClientService
      .getBlob(`${ environment.apiURL }/api/download_bulk_tax_report/${ data.id }`)
      .pipe(
        map(x => x as Blob)
      )
      .subscribe({
        next: (res) => {
          const filename = `bulk_tax_report_${ data.rd_document_type }_${ moment(data.header_issue_date_start, 'DD/MM/YYYY').format('YYYYMMDD') }-${ moment(data.header_issue_date_end, 'DD/MM/YYYY').format('YYYYMMDD') }.xlsx`;
          this.utilitiesService.downloadFile(res, filename);
          this.isLoadingOther = false;
          this.utilitiesService.toggleLoadingCell(rowEvent.targetElement, rowEvent.loadingElement);
        },
        error: (err) => {
          console.error(err);
          this.isLoadingOther = false;
          this.utilitiesService.toggleLoadingCell(rowEvent.targetElement, rowEvent.loadingElement);
          if (!this.handleModalService.hasModal('failedModal')) {
            const errorMessage = this.utilitiesService.transformErrorsToTextModal(err.error);
            this.handleModalService.connectFailedModal(errorMessage);
          }
        }
      });
  }

  private getStatus(status: number): number {
    if (status === 0 || status === 1) {
      return 4;
    } else if (status === 3) {
      return 8;
    }
    return status
  }

  private getPayloadLoadBulkTaxReport(): PayloadLoadBulkTaxReportModel {
    const formValue = this.form.value;
    const payload: PayloadLoadBulkTaxReportModel = {
      created_at_start: formValue['docu_date_period'] && formValue['docu_date_period'][0] ? moment(formValue['docu_date_period'][0]).format('YYYY-MM-DD') : undefined,
      created_at_end: formValue['docu_date_period'] && formValue['docu_date_period'][1] ? moment(formValue['docu_date_period'][1]).format('YYYY-MM-DD') : undefined,
      corporate_id: formValue['corporate_id'] ?? undefined,
      corporate_branch_id: formValue['corporate_branch_id'] ?? undefined,
      rd_document_type: formValue['rd_document_type'] ?? undefined,
      sort: this.sortBy,
      sort_type: this.sortMode
    };
    return payload;
  }

  private getPayloadGenerateBulkTaxReport(): PayloadGenerateBulkTaxReportModel {
    const formValue = this.form.value;
    const payload: PayloadGenerateBulkTaxReportModel = {
      header_issue_date_start: moment(formValue['docu_date_period'][0]).format('YYYY-MM-DD'),
      header_issue_date_end: moment(formValue['docu_date_period'][1]).format('YYYY-MM-DD'),
      corporate_id: formValue['corporate_id'] ?? undefined,
      corporate_branch_id: formValue['corporate_branch_id'] ?? undefined,
      rd_document_type: formValue['rd_document_type'] ?? undefined
    };
    return payload;
  }

}
